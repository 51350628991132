<!-- 环境样品库 -->
<template>
  <div class="envBank">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'环境样本管理系统'" />
    <Business :business="business" />
    <appsOfImg :appsData="appsData" />
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import appsOfImg from "./components/appsOfImg.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    appsOfImg
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner9.png"),
      business: {
        text: "环境样本库是研究环境当时状态和随时空发展变化的一项重要工具，环境样本库通常选择在当时生态环境状态下具有代表性的样品，如大气样品、土壤样品、水样品和沉淀物样品、以及环境生物样品等，对这些样品进行系统性的采集、制备，提供必要条件用于长期而稳定地保存。通过环境样本库信息管理系统，建立业务流程管理制度，实现样品从采集、入库到研究使用的规范化管理；通过环境样本库信息管理系统，建立样品数据库，管理库存样品存储信息，管理库存样品分析研究数据；通过物联网技术和GIS技术动态采集环境数据并与库存样本匹配，从而建成样品与环境信息数据中心。环境样本库通过信息管理系统建立对外分级共享机制，实现资源共享。",
        Imgs: []
      },
      appsData: {
        text: "土壤是人类赖以生存的环境要素，是农业可持续发展的物质基础。土壤样品携载丰富的环境特征信息，对于掌握不同历史阶段土壤环境状况及变化趋势有着不可替代的作用。国家土壤质量广州观测实验站基于宝晟云平台建立的“土壤样品库物联网监控平台及数据获取与处理系统”，是广东省农业科学院长期累积土壤样品和土壤环境质量信息的重要平台。通过该平台实现土壤样本采集、制备、保存统一规范，管理样品出入库流程、样品编码、样品存储、信息录入校验、检索、统计分析等。系统通过物联网技术动态采集土壤样品现场的气象数据、土壤墒情数据，整合土壤样品采集现场的原始信息（包括文字、图片、影像等），样品理化信息，样品研究试验数据等，建立了土壤样品数据库，并通过相应的机制实现土壤样品资源的共享，成为农业资源环境科学研究的基础平台。",
        imgUrl: require("@/assets/images/cases/content/apps-envBank01.png")
      },
    }
  }
}
</script>

<style lang="less" scoped>
</style>